import { AppBar } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useStore } from "./hooks/useStore";

export function Header() {
  const token = useStore((store) => store.token);
  const setToken = useStore((store) => store.setToken);

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Little River Admin
        </Typography>
        <Button color="inherit" onClick={() => setToken(null)}>
          {token ? "Logout" : "Log in"}
        </Button>
      </Toolbar>
    </AppBar>
  );
}
