import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { ReleaseType } from "../../types/types";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import generateAPI from "../../utils/api";

type Props = {
  isVisible: boolean;
  onClose: () => void;
  productionId: string;
};

const api = generateAPI();

export function AddReleaseDialog({ isVisible, onClose, productionId }: Props) {
  const [name, setName] = useState("");

  const queryClient = useQueryClient();

  const releaseToAdd: ReleaseType = {
    name,
    production: productionId,
  };

  const addReleaseMutation = useMutation(
    () => api.release.add.post(releaseToAdd),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["releases"]);
      },
    }
  );

  return (
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle>Add Release</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="email"
          fullWidth
          variant="standard"
          value={name}
          onChange={(evt) => setName(evt.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={async () => {
            if (!name) {
              return;
            }

            addReleaseMutation.mutate();
            onClose();
          }}
        >
          Add Release
        </Button>
      </DialogActions>
    </Dialog>
  );
}
