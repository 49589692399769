import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import useAccount from "./hooks/useAccount";
import Welcome from "./Welcome";
import { createTheme, ThemeProvider } from "@mui/material";
import BrandPage from "./containers/BrandPage/BrandPage";
import Production from "./containers/ProductionPage/Production";
import Release from "./containers/ReleasePage/Release";
import Video from "./containers/VideoPage/Video";

const theme = createTheme({
  palette: {
    primary: {
      main: "#333",
    },
  },
});

export default function Router() {
  const { token, dialog } = useAccount();

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {dialog}
        <Routes>
          {token ? (
            <>
              <Route path="/" element={<App />} />
              <Route path="/brand/:brandId" element={<BrandPage />} />
              <Route path="/release/:releaseId" element={<Release />} />
              <Route path="/video/:videoId" element={<Video />} />
              <Route
                path="/production/:productionId"
                element={<Production />}
              />
            </>
          ) : (
            <Route path="/" element={<Welcome />} />
          )}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
