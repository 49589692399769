import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ProductionKind, ProductionType } from "../../types/types";
import MenuItem from "@mui/material/MenuItem";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import generateAPI from "../../utils/api";

type Props = {
  isVisible: boolean;
  onClose: () => void;
  brandId: string;
};

const api = generateAPI();

export function AddProductionDialog({ isVisible, onClose, brandId }: Props) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [purchaseURL, setPurchaseURL] = useState("");
  const [productionKindSelect, setProductionKindSelect] =
    useState<ProductionKind>("series");

  const queryClient = useQueryClient();

  const productionToAdd: ProductionType = {
    name,
    description,
    purchaseURL,
    productionKind: productionKindSelect,
    brand: brandId as string,
  };

  const addProductionMutation = useMutation(
    () => api.production.add.post(productionToAdd),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["productions"]);
      },
    }
  );

  return (
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle>Add Production</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="email"
          fullWidth
          variant="standard"
          value={name}
          onChange={(evt) => setName(evt.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="description"
          label="Description"
          type="text"
          value={description}
          onChange={(evt) => setDescription(evt.target.value)}
          fullWidth
          variant="standard"
        />
        <TextField
          autoFocus
          margin="dense"
          id="purchaseURL"
          label="Description"
          type="text"
          value={purchaseURL}
          onChange={(evt) => setPurchaseURL(evt.target.value)}
          fullWidth
          variant="standard"
        />
        <FormControl fullWidth sx={{ marginTop: 4 }}>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={productionKindSelect}
            label="Age"
            onChange={(e: SelectChangeEvent) =>
              setProductionKindSelect(e.target.value as ProductionKind)
            }
          >
            <MenuItem value={"series"}>Series</MenuItem>
            <MenuItem value={"movie"}>Movie</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={async () => {
            if (!name) {
              return;
            }

            addProductionMutation.mutate();
            onClose();
          }}
        >
          Add Production
        </Button>
      </DialogActions>
    </Dialog>
  );
}
