import create from "zustand";
import { persist } from "zustand/middleware";

type InitialState = {
  token: string | null;
};

const initialState: InitialState = {
  token: null,
};

export const useStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      setToken: (token: string | null) => set((state) => ({ token })),
    }),
    {
      name: "little-river-admin", // unique name
    }
  )
);
