import React, { useState } from "react";
import "../../App.css";
import { Box, Container, Tab, Tabs } from "@mui/material";
import { Header } from "../../Header";
import { useQuery } from "react-query";
import generateAPI from "../../utils/api";
import { Breadcrumbs, Link, Paper, Typography } from "@mui/material";
import { VideoGetResponse } from "../../types/types";
import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import { VideoThumbnailPanel } from "./VideoThumbnailPanel";
import Button from "@mui/material/Button";
import { AddVideoDialog } from "../ReleasePage/AddVideoDialog";

const api = generateAPI();

function Video() {
  const { videoId } = useParams();
  const [modifyDialogVisible, setModifyDialogVisible] = useState(false);

  const { data: videoGetResponse, refetch } = useQuery<VideoGetResponse>(
    ["video", videoId],
    () => api.video.view[videoId as string].get()
  );

  if (!videoGetResponse) {
    return null;
  }

  const video = videoGetResponse.video;
  const release = video.release;
  invariant(typeof release !== "string", "Release must not be a string!");
  const production = release.production;
  invariant(typeof production !== "string");
  const brand = production.brand;
  invariant(typeof brand !== "string");

  return (
    <>
      <Header />
      {modifyDialogVisible && (
        <AddVideoDialog
          isVisible={modifyDialogVisible}
          onClose={() => setModifyDialogVisible(false)}
          videoToUpdate={video}
        />
      )}
      <Container maxWidth={"lg"} sx={{ marginTop: 2 }}>
        <Box justifyContent={"space-between"} display={"flex"}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={`/brand/${brand._id}`}
            >
              {brand.name}
            </Link>

            <Link
              underline="hover"
              color="inherit"
              href={`/production/${production._id}`}
            >
              {production.name}
            </Link>

            <Link
              underline="hover"
              color="inherit"
              href={`/release/${release._id}`}
            >
              {release.name}
            </Link>
            <Typography color="text.primary">
              {videoGetResponse.video.title}
            </Typography>
          </Breadcrumbs>
          <Button
            variant={"contained"}
            onClick={() => setModifyDialogVisible(true)}
          >
            Edit Video
          </Button>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={0} onChange={(e, val) => console.log("e", val)}>
            <Tab
              label={`${brand?.name} - ${production.name} - ${videoGetResponse.video.title}`}
            />
          </Tabs>

          <Paper sx={{ padding: 2 }}>
            <Typography variant={"h6"}>{video.title}</Typography>

            <Typography variant={"subtitle1"}>
              Description: {video.description ? video.description : "None"}
            </Typography>

            <Typography variant={"subtitle1"}>
              Contract address:{" "}
              <a
                href={`https://etherscan.io/address/${video.contractAddress}`}
                target={"_blank"}
              >
                {video.contractAddress ? video.contractAddress : "None"}
              </a>
            </Typography>

            <VideoThumbnailPanel video={video} refetch={refetch} />
          </Paper>
        </Box>
      </Container>
    </>
  );
}

export default Video;
