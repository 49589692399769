import React, { useCallback, useState } from "react";
import axios from "axios";
import { serverURL } from "../../utils/utils";
import { useDropzone } from "react-dropzone";
import { VideoType } from "../../types/types";
import { useStore } from "../../hooks/useStore";
import { Box, Paper, Typography } from "@mui/material";

type Props = {
  video: VideoType;
  refetch: () => void;
};

export function VideoThumbnailPanel({ video, refetch }: Props) {
  const token = useStore((store) => store.token);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setLoading(true);
      const file = acceptedFiles[0];

      const formData = new FormData();

      formData.append("image", file);
      formData.append("videoId", video._id as string);

      await axios.post(
        `${serverURL}/video/updateThumbnail/${video._id}`,
        formData,
        {
          headers: {
            token: token as string,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      await refetch();
      setLoading(false);
    },
    [video._id, token]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box {...getRootProps()} marginTop={1}>
      <Typography fontSize={18} fontWeight={500}>
        Thumbnail Panel
      </Typography>

      <Box display={"flex"}>
        <Box
          sx={{
            height: 250,
            width: "50%",
            background: `url(${serverURL}/thumbnails/${video.thumbnailURL})`,
            backgroundSize: "cover",
          }}
        />
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography variant={"h6"}>Drop the new thumbnail here</Typography>
          ) : (
            <Typography variant={"h6"}>
              {loading
                ? "Updating..."
                : "Drag 'n' drop a new thumbnail to update"}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
