import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { VideoType } from "../../types/types";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import generateAPI from "../../utils/api";
import invariant from "tiny-invariant";

type Props = {
  isVisible: boolean;
  onClose: () => void;
  releaseId?: string;
  videoToUpdate?: VideoType;
};

const api = generateAPI();

type VideoServerProps = "views" | "time" | "duration" | "thumbnailURL";
interface AddVideoType extends Omit<VideoType, VideoServerProps | "_id"> {}
interface ModifyVideoType
  extends Omit<VideoType, VideoServerProps | "release" | "videoId"> {}

export function AddVideoDialog({
  isVisible,
  onClose,
  releaseId,
  videoToUpdate,
}: Props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [tokenIdsInput, setTokenIdsInput] = useState("");
  const [videoId, setVideoId] = useState("");
  const queryClient = useQueryClient();

  useEffect(() => {
    if (videoToUpdate) {
      setTokenIdsInput(videoToUpdate.tokenIds.join(","));
      setDescription(videoToUpdate.description);
      setTitle(videoToUpdate.title);
      setContractAddress(videoToUpdate.contractAddress ?? "");
    }
  }, []);

  async function clearAndCloseDialog() {
    if (videoToUpdate) {
      await queryClient.invalidateQueries(["video", videoToUpdate._id]);
    } else {
      await queryClient.invalidateQueries(["videos"]);
    }

    setTokenIdsInput("");
    setVideoId("");
    setTitle("");
    setDescription("");
    setContractAddress("");

    onClose();
  }

  const addVideoMutation = useMutation(
    async () => {
      invariant(releaseId, "Release ID not found!");

      const videoToAdd: AddVideoType = {
        title,
        contractAddress,
        videoId,
        description,
        tokenIds: tokenIdsInput
          ? tokenIdsInput.split(",").map((x) => Number(x))
          : [],
        release: releaseId,
      };

      return await api.video.add.post(videoToAdd);
    },
    {
      onSuccess: async () => {
        await clearAndCloseDialog();
      },
    }
  );

  const updateVideoMutation = useMutation(
    async () => {
      invariant(videoToUpdate);

      const videoToModify: ModifyVideoType = {
        _id: videoToUpdate._id,
        title,
        contractAddress,
        description,
        tokenIds: tokenIdsInput
          ? tokenIdsInput.split(",").map((x) => Number(x))
          : [],
      };

      return await api.video.update.post(videoToModify);
    },
    {
      onSuccess: async () => {
        await clearAndCloseDialog();
      },
    }
  );

  return (
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle>Add Video</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Title"
          type="text"
          fullWidth
          variant="standard"
          value={title}
          onChange={(evt) => setTitle(evt.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="description"
          label="Description"
          type="text"
          fullWidth
          variant="standard"
          value={description}
          onChange={(evt) => setDescription(evt.target.value)}
        />
        {!videoToUpdate && (
          <TextField
            autoFocus
            margin="dense"
            id="videoId"
            label="Video ID (cloudflare videoId)"
            type="text"
            fullWidth
            variant="standard"
            value={videoId}
            onChange={(evt) => setVideoId(evt.target.value)}
          />
        )}
        <TextField
          autoFocus
          margin="dense"
          id="contractAddress"
          label="Gated contract address (optional)"
          type="text"
          fullWidth
          variant="standard"
          value={contractAddress}
          onChange={(evt) => setContractAddress(evt.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="tokenIds"
          label="Gated tokenIds for above contract (comma-separated)"
          type="text"
          fullWidth
          variant="standard"
          value={tokenIdsInput}
          onChange={(evt) => setTokenIdsInput(evt.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={async () => {
            if (
              !title ||
              addVideoMutation.isLoading ||
              updateVideoMutation.isLoading
            ) {
              return;
            }

            if (videoToUpdate) {
              updateVideoMutation.mutate();
            } else {
              addVideoMutation.mutate();
            }
          }}
        >
          {addVideoMutation.isLoading || updateVideoMutation.isLoading
            ? "Loading..."
            : videoToUpdate
            ? "Update Video"
            : "Add Video"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
