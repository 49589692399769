import { useEffect, useState } from "react";
import { useStore } from "./useStore";
import generateAPI from "../utils/api";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { UserAccessRequestParams } from "../types/types";

export default function useAccount() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const token = useStore((state) => state.token);
  const setToken = useStore((state) => state.setToken);
  const api = generateAPI();

  useEffect(() => {
    async function check() {
      if (!token) return;

      try {
        const userData = await api.user.me.get();

        if (userData.error) {
          setToken(null);
        } else {
          setToken(userData.token);
        }
      } catch (e) {
        setToken(null);
      }
    }

    check();
  }, []);

  const logout = () => setToken(null);

  const dialog = !token ? (
    <Dialog open={true} onClose={() => {}}>
      <DialogTitle>Log in</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          value={email}
          onChange={(evt) => setEmail(evt.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="password"
          label="Password"
          type="password"
          value={password}
          onChange={(evt) => setPassword(evt.target.value)}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        {/*<Button onClick={() => {}}>Cancel</Button>*/}
        <Button
          onClick={async () => {
            const loginParams: UserAccessRequestParams = {
              email,
              password,
            };
            const { token } = await api.user.login.post(loginParams);

            if (token) {
              setToken(token);
            }
          }}
        >
          Log in
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;

  return { token, dialog, logout };
}
