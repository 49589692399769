import React, { useState } from "react";
import "../../App.css";
import { Box, Container, Tab, Tabs } from "@mui/material";
import { Header } from "../../Header";
import Button from "@mui/material/Button";
import { useQuery } from "react-query";
import generateAPI from "../../utils/api";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import {
  ReleaseGetResponse,
  VideoListResponse,
  VideoType,
} from "../../types/types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate, useParams } from "react-router-dom";
import { AddVideoDialog } from "./AddVideoDialog";
import invariant from "tiny-invariant";

const api = generateAPI();

function Release() {
  const [addVideoVisible, setAddVideoVisible] = useState(false);
  const { releaseId } = useParams();
  const navigate = useNavigate();

  const { data: videoListResponse } = useQuery<VideoListResponse>(
    ["videos", releaseId],
    () => api.video.listForRelease[releaseId as string].get()
  );

  const { data: releaseGetResponse } = useQuery<ReleaseGetResponse>(
    ["Release", releaseId],
    () => api.release.view[releaseId as string].get()
  );

  if (!releaseGetResponse) {
    return null;
  }

  invariant(typeof releaseGetResponse.release.production !== "string");

  const production = releaseGetResponse.release.production;
  const brand = production.brand;

  invariant(typeof brand !== "string");

  return (
    <>
      <Header />

      <AddVideoDialog
        isVisible={addVideoVisible}
        onClose={() => setAddVideoVisible(false)}
        releaseId={releaseId as string}
      />
      <Container maxWidth={"lg"} sx={{ marginTop: 2 }}>
        <Box justifyContent={"space-between"} display={"flex"}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={`/brand/${brand._id}`}
            >
              {brand.name}
            </Link>

            <Link
              underline="hover"
              color="inherit"
              href={`/production/${production._id}`}
            >
              {production.name}
            </Link>
            <Typography color="text.primary">
              {releaseGetResponse.release.name}
            </Typography>
          </Breadcrumbs>
          <Button
            variant={"contained"}
            onClick={() => setAddVideoVisible(true)}
          >
            Add Video
          </Button>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={0} onChange={(e, val) => console.log("e", val)}>
            <Tab
              label={`${brand.name} - ${production.name} - ${releaseGetResponse.release.name} Videos`}
            />
          </Tabs>
        </Box>

        {videoListResponse?.videos && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>VideoId</TableCell>
                  <TableCell>Gated Contract Address</TableCell>
                  <TableCell>Token id's</TableCell>
                  <TableCell>Views</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {videoListResponse.videos.map((video: VideoType, i: number) => (
                  <TableRow
                    hover={true}
                    key={i}
                    onClick={() => {
                      navigate(`/video/${video._id}`);
                    }}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {video.title}
                    </TableCell>
                    <TableCell>{video.description}</TableCell>
                    <TableCell>{video.videoId}</TableCell>
                    <TableCell>{video.contractAddress}</TableCell>
                    <TableCell>{JSON.stringify(video.tokenIds)}</TableCell>
                    <TableCell>{video.views ?? 0}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </>
  );
}

export default Release;
