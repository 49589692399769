import React, { useState } from "react";
import "./App.css";
import { Box, Container, Tab, Tabs } from "@mui/material";
import { Header } from "./Header";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useMutation, useQuery, useQueryClient } from "react-query";
import generateAPI from "./utils/api";
import { BrandListResponse, BrandType } from "./types/types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";

const api = generateAPI();

function App() {
  const [addBrandVisible, setAddBrandVisible] = useState(false);
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: brands } = useQuery(["brands"], () => api.brand.list.get());

  const brandToAdd: BrandType = { name, description, slug };

  const addBrandMutation = useMutation(() => api.brand.add.post(brandToAdd), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["brands"]);
    },
  });

  const brandListResponse: BrandListResponse = brands;

  return (
    <>
      <Header />

      <Dialog open={addBrandVisible} onClose={() => setAddBrandVisible(false)}>
        <DialogTitle>Add Brand</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="email"
            fullWidth
            variant="standard"
            value={name}
            onChange={(evt) => setName(evt.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            type="text"
            value={description}
            onChange={(evt) => setDescription(evt.target.value)}
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="slug"
            label="Slug"
            type="text"
            value={slug}
            onChange={(evt) => setSlug(evt.target.value)}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddBrandVisible(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              if (!name) {
                return;
              }

              addBrandMutation.mutate();
              setAddBrandVisible(false);
            }}
          >
            Add Brand
          </Button>
        </DialogActions>
      </Dialog>

      <Container maxWidth={"lg"} sx={{ marginTop: 2 }}>
        <Box justifyContent={"flex-end"} display={"flex"}>
          <Button
            variant={"contained"}
            onClick={() => setAddBrandVisible(true)}
          >
            Add Brand
          </Button>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={0} onChange={(e, val) => console.log("e", val)}>
            <Tab label="Brands" />
            {/*<Tab label="Productions" />*/}
          </Tabs>
        </Box>

        {brandListResponse?.brands && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Slug</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {brandListResponse.brands.map((brand, i) => (
                  <TableRow
                    hover={true}
                    key={i}
                    onClick={() => {
                      navigate(`/brand/${brand._id}`);
                    }}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {brand.name}
                    </TableCell>
                    <TableCell>{brand.description}</TableCell>
                    <TableCell>{brand.slug}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </>
  );
}

export default App;
