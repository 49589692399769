import React from "react";
import "./App.css";
import {
  Box,
  Container,
  createTheme,
  Paper,
  ThemeProvider,
} from "@mui/material";
import { Header } from "./Header";

const theme = createTheme({
  palette: {
    primary: {
      main: "#333",
    },
  },
});

function Welcome() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Header />
        <Container maxWidth={"lg"}>
          <Paper>
            <Box>Welcome</Box>
          </Paper>
        </Container>
      </>
    </ThemeProvider>
  );
}

export default Welcome;
