import React, { useState } from "react";
import "../../App.css";
import {
  Box,
  Breadcrumbs,
  Container,
  Link,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Header } from "../../Header";
import Button from "@mui/material/Button";
import { useQuery } from "react-query";
import generateAPI from "../../utils/api";
import { ProductionGetResponse, ReleaseListResponse } from "../../types/types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate, useParams } from "react-router-dom";
import { AddReleaseDialog } from "./AddReleaseDialog";
import invariant from "tiny-invariant";

const api = generateAPI();

function Production() {
  const [addReleaseVisible, setAddReleaseVisible] = useState(false);
  const { productionId } = useParams();
  const navigate = useNavigate();

  const { data: releaseListResponse } = useQuery<ReleaseListResponse>(
    ["releases", productionId],
    () => api.release.listForProduction[productionId as string].get()
  );

  const { data: productionGetResponse } = useQuery<ProductionGetResponse>(
    ["Production", productionId],
    () => api.production.view[productionId as string].get()
  );

  if (!productionGetResponse) {
    return null;
  }

  invariant(typeof productionGetResponse.production.brand != "string");

  return (
    <>
      <Header />

      <AddReleaseDialog
        isVisible={addReleaseVisible}
        onClose={() => setAddReleaseVisible(false)}
        productionId={productionId as string}
      />
      <Container maxWidth={"lg"} sx={{ marginTop: 2 }}>
        <Box justifyContent={"space-between"} display={"flex"}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={`/brand/${productionGetResponse.production.brand._id}`}
            >
              {productionGetResponse.production.brand.name}
            </Link>

            <Typography color="text.primary">
              {productionGetResponse?.production?.name}
            </Typography>
          </Breadcrumbs>
          <Button
            variant={"contained"}
            onClick={() => setAddReleaseVisible(true)}
          >
            Add Release
          </Button>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={0} onChange={(e, val) => console.log("e", val)}>
            <Tab label={`${productionGetResponse?.production.name} Releases`} />
          </Tabs>
        </Box>

        {releaseListResponse?.releases && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {releaseListResponse.releases.map((release, i) => (
                  <TableRow
                    hover={true}
                    key={i}
                    onClick={() => {
                      navigate(`/release/${release._id}`);
                    }}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {release.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </>
  );
}

export default Production;
