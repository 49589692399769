import React, { useState } from "react";
import "../../App.css";
import { Box, Container, Tab, Tabs } from "@mui/material";
import { Header } from "../../Header";
import Button from "@mui/material/Button";
import { useQuery } from "react-query";
import generateAPI from "../../utils/api";
import { BrandGetResponse, ProductionListResponse } from "../../types/types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate, useParams } from "react-router-dom";
import { AddProductionDialog } from "./AddProductionDialog";

const api = generateAPI();

function BrandPage() {
  const [addProductionVisible, setAddProductionVisible] = useState(false);
  const { brandId } = useParams();
  const navigate = useNavigate();
  const { data: productionListResponse } = useQuery<ProductionListResponse>(
    ["productions", brandId],
    () => api.production.listForBrand[brandId as string].get()
  );

  const { data: brandGetResponse } = useQuery<BrandGetResponse>(
    ["Brand", brandId],
    () => api.brand.view[brandId as string].get()
  );

  return (
    <>
      <Header />

      <AddProductionDialog
        isVisible={addProductionVisible}
        onClose={() => setAddProductionVisible(false)}
        brandId={brandId as string}
      />
      <Container maxWidth={"lg"} sx={{ marginTop: 2 }}>
        <Box justifyContent={"flex-end"} display={"flex"}>
          <Button
            variant={"contained"}
            onClick={() => setAddProductionVisible(true)}
          >
            Add Production
          </Button>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={0} onChange={(e, val) => console.log("e", val)}>
            <Tab label={`${brandGetResponse?.brand.name} Productions`} />
          </Tabs>
        </Box>

        {productionListResponse?.productions && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Production Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productionListResponse.productions.map((production, i) => (
                  <TableRow
                    hover={true}
                    key={i}
                    onClick={() => {
                      navigate(`/production/${production._id}`);
                    }}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {production.name}
                    </TableCell>
                    <TableCell>{production.description}</TableCell>
                    <TableCell>{production.productionKind}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </>
  );
}

export default BrandPage;
